import "./Footer.css";
import React from 'react';

const Footer =({children}) =>{

      //<ToolBar/>
      //<Sides/>
      //<Backdrop/>
    return(
        <>
        <footer className="footer">
        Copyright &copy; 2022 NiKAD.net - all rights reserved.
        </footer>
        </>
    )
}

export default Footer;
