import './BusinessCard.css';
import React from 'react';
import {FaTwitterSquare, FaEnvelope, FaGlobe, FaGithubSquare, FaBlog} from 'react-icons/fa';

const BusinessCard =(props) =>{
    const profile = props;
    const rotate = profile.norotate ? 0 : Math.ceil(Math.random() * 4) - 2;

    return(
        <>
        <div className="business-card" style={{zIndex:`${profile.selected ? 100:0}`, transform:`rotate(${rotate}deg)`}}>
          <img className="logo" alt="" src={profile.company_logo_url}/>
          <section className="details">
            <div className="name">{profile.name}</div>
            <div className="job-title">{profile.job_title}</div>
            <hr/>
            <div className="company" style={{color:profile.color}}>{profile.company}</div>
            <div className="location">{profile.location}</div>
            <div className="date">
            {profile.start_date &&
              profile.start_date
            }&nbsp;
            {profile.end_date &&
              `to ${profile.end_date}`
            }
            </div>
            <div className="links">
            {profile.twitter_username &&
              <a className="twitter" href={`https://twitter.com/${profile.twitter_username}`}><FaTwitterSquare/></a>
            }
            {profile.email &&
              <a className="email" href={`mailto:${profile.email}`}><FaEnvelope/></a>
            }
            {profile.html_url &&
              <a className="html" href={`${profile.html_url}`}><FaGlobe/></a>
            }
            {profile.github_url &&
              <a className="github" href={`${profile.github_url}`}><FaGithubSquare/></a>
            }
            {profile.blog &&
              <a className="blog" href={`${profile.blog}`}><FaBlog/></a>
            }
            </div>
          </section>
          <img className="hero-image" alt="" src={profile.avatar_url}/>
        </div>
        </>
    )
}

export default BusinessCard;
