import './Header.css';
import React from 'react';

const Header =({children}) =>{

      //<ToolBar/>
      //<Sides/>
      //<Backdrop/>
    return(
        <>
        <header className="header">
        NiKAD.net
        </header>
        </>
    )
}

export default Header;
