import './App.css';
import HomeView from "./views/HomeView";
import EditCardView from "./views/EditCardView";
import AboutView from "./views/AboutView";
import CardsView from "./views/CardsView";
import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route index element={<HomeView />} path="/"/>
        <Route index element={<AboutView />} path="/about"/>
        <Route index element={<CardsView />} path="/cards"/>
        <Route index element={<EditCardView />} path="/cards/edit"/>
      </Routes>
    </BrowserRouter>
  );
}

export default App;

  /*<Form onSubmit={addNewBusinessCard}/>*/
