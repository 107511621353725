import './BusinessCardList.css';
import React, {useState} from 'react';
import BusinessCard from "./BusinessCard";
import {FaArrowAltCircleRight, FaArrowAltCircleLeft} from 'react-icons/fa';

const BusinessCardList =(props) =>{
    const [current, setCurrent] = useState(0);
    const length = props.profiles.length;

    if (!Array.isArray(props.profiles) || props.profiles.length <= 0) {
      // todo hide next/prev
    }
    const nextCard = () => {
      setCurrent(current >= length-1 ? 0 : current+1);
    };
    const prevCard = () => {
      setCurrent(current <= 0 ? length-1 : current-1);
    };
    return(
        <>
        <div className="business-card-list">
        <div className="control">
          <FaArrowAltCircleLeft onClick={prevCard}/>
          <span> {current+1} of {length} </span>
          <FaArrowAltCircleRight onClick={nextCard}/>
        </div>
        {props.profiles.map((profile, index) => <BusinessCard key={profile.id} selected={current === index} {...profile}/>)}
        </div>
        </>
    )
}

export default BusinessCardList;
