import React from 'react';
import Layout from "../components/Layout";

const HomeView = () =>{
    return(
        <>
        <Layout>
        <div className="home">
          <article>
            <header class="entry-header">
              <h1 class="entry-title"><span>Keith Dunbar</span></h1>
            </header>
            <div class="entry-content">
              <p>Focussing on producing quality software, I program in Java, C, JavaScript and web technologies using the SCRUM methodology. I use innovation and observation to troubleshoot software. Working both independently and collaboratively, my work excites and motivates me. Time management is important, and I prioritise tasks to gain the best results.<br/> I am looking for the exciting challenge of a new post with opportunities to experience new technologies, develop new skills and learn a new codebase.</p>
              <h2>Technical Experience Summary</h2>
              <p>Programming Languages</p>
              <ul><li>Java</li><li>J2EE</li><li>JavaScript</li><li>C</li></ul>
              <p>Web Technologies</p>
              <ul><li>HTML/HTML 5</li><li>Struts/Tiles/JSP</li><li>XML/JSON</li><li>Polymer</li></ul>
              <p>Databases</p>
              <ul><li>SQL Server</li><li>Oracle</li><li>MySQL</li><li>DB2</li></ul>
              <h1>Software Development</h1>
              <div><h3>JAVA DEVELOPMENT</h3></div>
              <div>High performance, low-latency network and multi-threaded applications. Java, Spring, Spring Boot, J2EE</div>
              <div><h3>Database</h3></div>
              <div>Schema design. In depth knowledge of Oracle and MySQL.</div>
              <div>
              <h3>Networking</h3>
              </div>
              <div>DNS configuration, Website and Domain Setup and maintenance.</div>
            </div>
          </article>
        </div>
        </Layout>
        </>
    )
}

export default HomeView;
