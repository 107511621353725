import './EditCardView.css'
import React, {useState} from 'react';
import Layout from "../components/Layout";
import BusinessCard from "../components/BusinessCard";

const EditCardView = () =>{
  const data = {
      "id": -1,
      "name": "",
      "company": "",
      "company_logo_url": "",
      "html_url": "",
      "color": "",
      "location": "",
      "job_title": "",
      "start_date": "",
      "end_date": "",
      "avatar_url": "",
      "github_url": "",
      "blog": "",
      "email": "",
      "bio": "",
      "twitter_username": ""
  };
    const [profile, setProfile] = useState(data);
    const handleNameChange = event => setProfile({...profile, "name": event.target.value});
    const handleCompanyChange = event => setProfile({...profile, "company": event.target.value});
    const handleUrlChange = event => setProfile({...profile, "html_url": event.target.value});
    const handleLocationChange = event => setProfile({...profile, "location": event.target.value});
    const handleJobTitleChange = event => setProfile({...profile, "job_title": event.target.value});
    const handleEmailChange = event => setProfile({...profile, "email": event.target.value});
    const handleBioChange = event => setProfile({...profile, "bio": event.target.value});
    const handleTwitterChange = event => setProfile({...profile, "twitter_username": event.target.value});
    const useGravitar = () => setProfile({...profile, "avatar_url":"http://2.gravatar.com/avatar/05e4736f2dd78bf5fa11063e048de263"});
    const clearImage = () => setProfile({...profile, "avatar_url":""});
    const submitJson = () => {
        fetch("http://localhost:8080/businesscards/add", {
          'method': 'POST',
          'headers': {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          'body': JSON.stringify(profile)})
          .then(response => response.json())
          .then(responseData => {
            console.log("Card created: ", responseData.id)
          })
          .catch(err => {
            console.log("Error adding card")
          });
      setProfile(data);
    };
    // company_logo_url
    // color
    // start_date
    // end_date
    // avatar_url
    // github_url
    // blog

    return(
        <>
        <Layout>
        <div className="edit-card">
          <h1>Card Editor</h1>
          <div className="card-view">
            <BusinessCard key={profile.id} selected="true" {...profile} norotate="true"/>
          </div>
          <div className="content">
            <div><span>ID: {profile.id}</span></div>
            <div><input type="text" placeholder="Name" value={profile.name} onChange={handleNameChange}/></div>
            <div><input type="text" placeholder="Job Title" value={profile.job_title} onChange={handleJobTitleChange}/></div>
            <div><input type="text" placeholder="Company" value={profile.company} onChange={handleCompanyChange}/></div>
            <div><input type="text" placeholder="Company URL" value={profile.html_url} onChange={handleUrlChange}/></div>
            <div><input type="text" placeholder="Location" value={profile.location} onChange={handleLocationChange}/></div>
            <div><input type="text" placeholder="Email" value={profile.email} onChange={handleEmailChange}/></div>
            <div><button onClick={useGravitar}>Get Gravitar Image</button><button onClick={clearImage}>Clear Image</button></div>
            <div><textarea placeholder="Bio" value={profile.bio} onChange={handleBioChange}/></div>
            <div><input type="text" placeholder="Twitter" value={profile.twitter_username} onChange={handleTwitterChange}/></div>
          </div>
          <div className="rawView">
            <pre>{JSON.stringify(profile, null, 2) }</pre>
          </div>
          <div className="post">
            <button onClick={submitJson}>Add</button>
          </div>
        </div>
        </Layout>
        </>
    )
}

export default EditCardView;
