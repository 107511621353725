import React from 'react';
import Layout from "../components/Layout";

const AboutView = () =>{
    return(
        <>
        <Layout>
        <div className="home">
          <h1>About this site</h1>
          <p>Some content here</p>
        </div>
        </Layout>
        </>
    )
}

export default AboutView;
