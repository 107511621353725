import React from 'react';
import Toolbar from './Toolbar'
import Header from './Header'
import Footer from './Footer'

const Layout =({children}) =>{
    return(
        <>
        <div>
          <Header/>
          <Toolbar/>
        </div>
        <main>{children}</main>
        <div>
          <Footer/>
        </div>
        </>
    )
}

export default Layout;
