import "./Toolbar.css"
import React from 'react';
import { Link } from "react-router-dom";

const Toolbar = () =>{
    return(
        <nav className="main-nav">
          <ul>
            <li><Link to="/">Home</Link></li>
            <li><Link to="/cards">Business Cards</Link></li>
            <li><Link to="/cards/edit">Edit Card</Link></li>
            <li><Link to="/about">About</Link></li>
          </ul>
        </nav>
    )
}

export default Toolbar;
