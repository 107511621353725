import Layout from "../components/Layout";
import BusinessCardList from "../components/BusinessCardList";
import React, {useState, useEffect} from 'react';

const CardsView = () =>{

    const [data, setData] = useState([]);
    const fetchCards = () => {
      //fetch("http://localhost:8080/businesscards/all")
      fetch("/api/cardData.json")
        .then(response => response.json())
        .then(responseData => {
          setData([...data, ...responseData.business_cards]);
        })
        .catch(err => {
          // some error handling
        });
    };
    useEffect(fetchCards, []);
    return(
        <>
        <Layout>
        <div className="tools">
          <h1>Business Cards</h1>
          <BusinessCardList profiles={data}/>
        </div>
        </Layout>
        </>
    )
}

export default CardsView;
